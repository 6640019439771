import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionItem,
  PlatformExceptionList,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { ActionBar, SelectMark } from 'hudl-uniform-ui-components';
import pageHeroData from '../../../data/pages/components.yml';
import styles from './styles/actionbar.module.scss';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAthletes: [],
      footerTitle: '',
      footerText: '',
    };
  }

  toggleSelectMark = athleteInitials => {
    const { selectedAthletes } = this.state;

    if (selectedAthletes.indexOf(athleteInitials) > -1) {
      this.setState({
        selectedAthletes: selectedAthletes.filter(t => t !== athleteInitials),
      });
    } else {
      this.setState({
        selectedAthletes: [...selectedAthletes, athleteInitials],
      });
    }
  };

  isSelected(athleteInitials) {
    return this.state.selectedAthletes.indexOf(athleteInitials) > -1
      ? 'selected'
      : 'unselected';
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Action Bar"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Action Bar" />
        <PageNavigation>
          <PageNavigationLink>Functions</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>
        <Section title="Functions">
          <Paragraph>
            The action bar is displayed as soon as a single item is selected.
            Once it appears at the top of the selection area, it can be removed
            by performing a given action, deselecting the item(s) or clicking
            dismiss on the right.
          </Paragraph>
          <ComponentPreview name="ActionBarFunctions" layout="fullWidth">
            <div>
              <ActionBar
                isOpen={this.state.selectedAthletes.length > 0}
                infoText={
                  this.state.selectedAthletes.length + ' athletes selected'
                }
                actions={[
                  { icon: 'delete', label: 'Delete' },
                  { icon: 'share', label: 'Share' },
                ]}
                onClose={() =>
                  this.setState({
                    selectedAthletes: [],
                  })
                }
              />
              <div className={styles.actionRow}>
                <SelectMark
                  onClick={() => this.toggleSelectMark('mj')}
                  selectedState={this.isSelected('mj')}
                />
                Michael Jordan
              </div>
              <div className={styles.actionRow}>
                <SelectMark
                  onClick={() => this.toggleSelectMark('lb')}
                  selectedState={this.isSelected('lb')}
                />
                Larry Bird
              </div>
              <div className={styles.actionRow}>
                <SelectMark
                  onClick={() => this.toggleSelectMark('dr')}
                  selectedState={this.isSelected('dr')}
                />
                David Robinson
              </div>
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            An action can be performed on one or more items in different ways on
            mobile devices.
          </Paragraph>
          <PlatformExceptionList>
            <PlatformExceptionItem platform="apple">
              A combination of a{' '}
              <Link href="/components/buttons/button/design">button</Link> and{' '}
              <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/action-sheets/">
                Apple action sheet
              </Link>{' '}
              may be used in place of the action bar to let users initiate or
              complete tasks. In some cases destructive actions, like delete,
              may also be included. Alternatively if only one action can be
              taken include the action in the{' '}
              <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/bars/navigation-bars/">
                navigation bar
              </Link>{' '}
              instead.
            </PlatformExceptionItem>
            <PlatformExceptionItem platform="android">
              Contextual app bars work in place of the action bar and should be
              used in all cases.
            </PlatformExceptionItem>
          </PlatformExceptionList>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Selection Info</SectionSubhead>
          <Paragraph>
            <strong>
              The selection info area is required for all action bars.
            </strong>{' '}
            It appears on the left to balance available actions on the right.
          </Paragraph>

          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            Actions appear on the right to sit near the default action of
            dismiss. While there is no limit to the number of actions you may
            provide, there is a <em>minimum</em> of one.
          </Paragraph>
          <Paragraph>
            <strong>
              The actions listed should apply to all selected items.
            </strong>{' '}
            Once a selection is made, the user cannot make exceptions as to
            which items are affected by a given action.
          </Paragraph>

          <SectionSubhead>Position</SectionSubhead>
          <Paragraph>
            <List>
              <li>
                Location: The action bar appears at the top of the interface in
                which the items are selected.
              </li>
              <li>
                Elevation: Consistent with our{' '}
                <Link href="/guidelines/layering/elevation/design/#Stack%20Order">
                  stack order
                </Link>
                , the action bar has a level 2 elevation.
              </li>
              <li>
                Container: The action bar’s container is only as wide as the
                selection area, not the full page.
              </li>
            </List>
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Selection Info</SectionSubhead>
          <Paragraph>
            The selection info should display the total number of items
            selected, a label for those items, and the clarification that they
            are <strong>selected</strong>.
          </Paragraph>
          <Paragraph>
            Regardless of the selection count, stick to numerals—do not spell
            out smaller numbers.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="spell out numbers 1-9."
              img="actionbar-spellnumbers-dont"
            />
            <DontDoItem
              type="do"
              text="provide a clear count for how many they've selected."
              img="actionbar-spellnumbers-do"
            />
          </DontDo>
          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            <strong>The actions should always be verbs.</strong> Try sticking to
            a single word. In some cases, a phrase may be necessary. Just make
            it short and <em>actionable</em>.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a complete sentence for each action."
              img="actionbar-actions-dont"
            />
            <DontDoItem
              type="do"
              text="give them a single directive for the selected items."
              img="actionbar-actions-do"
            />
          </DontDo>
          <SectionSubhead>Case & Punctuation</SectionSubhead>
          <Paragraph>
            All selection info should be lowercase, not sentence case. (That
            would capitalize the first word, which we don’t want to do.)
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="capitalize any letter in the selection info."
              img="actionbar-case-dont"
            />
            <DontDoItem
              type="do"
              text="lowercase the entire string."
              img="actionbar-case-do"
            />
          </DontDo>
          <Paragraph>
            <strong>Treat actions as labels with title case.</strong>
          </Paragraph>
        </Section>
        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
